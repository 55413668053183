<template>
  <v-card-text class="text--primary article-text">
    <vue-markdown :source="content"></vue-markdown>
  </v-card-text>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .article-text {
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: 'Gentium Book Basic', serif;
  }
  .single-article {
    p {
      margin-bottom: 1rem;
    }
  }
</style>
