<template>
  <v-container class="px-1 pa-sm-5 single-article">
    <v-row v-if="$apollo.queries.post.loading" justify="center">
      <v-col :cols="12" :md="8">
        <v-card elevation="0">
          <v-skeleton-loader
            type="image, image, article, article, article"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col :cols="12" :md="8">
        <v-card class="mx-auto" elevation="0">
          <v-img class="white--text align-end" :src="post.featuredImage.node.mediaItemUrl" v-if="post.featuredImage">
            <v-card-title class="text-break"><h2 class="article-title">{{ post.title }}</h2></v-card-title>
            <v-card-subtitle class="pt-0">
              {{ post.date | moment('MMMM D, YYYY') }}
            </v-card-subtitle>
          </v-img>
          <v-card-subtitle class="text-break pb-0 d-flex">
            <div v-for="category in post.categories.nodes" :key="category.id" class="pr-1 category success--text">
              {{ category.name }}
            </div>
          </v-card-subtitle>
          <div class="d-flex flex-column excerpt">
            <article-content :content="sanitize(post.content)"/>
          </div>
          <v-list class="py-10 d-flex justify-center">
            <v-list-item class="grow">
              <v-list-item-avatar size="60" color="grey darken-3">
                <v-img class="elevation-6" alt="" :src="post.author.node.avatar.url"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Author</v-list-item-subtitle>
                <v-list-item-title>{{ post.author.node.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GET_POST from '../../graphql/blog/single.graphql'
// eslint-disable-next-line no-unused-vars
import sanitizeHtml from 'sanitize-html'
import ArticleContent from '../../components/Blog/ArticleContent'

export default {
  data () {
    return {
      id: this.$route.params.slug
    }
  },
  methods: {
    sanitize (html) {
      return sanitizeHtml(html, {
        allowedTags: ['img', 'p', 'h1', 'h2', 'h3', 'ol', 'li', 'strong', 'ul', 'a']
      })
    }
  },
  apollo: {
    $client: 'b',
    post: {
      query: GET_POST,
      variables () {
        return {
          id: this.id
        }
      }
    }
  },
  metaInfo () {
    return {
      title: this.post && this.post.seo.title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.post && (this.post.seo.metaDesc || this.post.seo.opengraphDescription)
      }]
    }
  },
  components: {
    ArticleContent
  }
}
</script>
<style lang="scss">
  .author {
    font-weight: 500;
  }
  .category {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    &:not(:last-child) {
      &:after {
        content: '|';
        margin-right: 0;
      }
    }
  }
  .single-article {
    p, li{
      margin-bottom: 2rem;
    }
    img {
      width: 100%;
    }
    strong {
      font-weight: 600 !important;
    }
    .article-title {
      text-shadow: 2px 4px 5px #1f1f1f;
      line-height: 1.5;
      margin-bottom: 0;
    }
    .article-text {
      font-size: 1.5rem;
      line-height: 1.5;
      font-family: 'Gentium Book Basic', serif;
    }
  }
</style>
